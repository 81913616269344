<template>

</template>

<script>
  export default {
    name: "logout",
    created() {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('type');
      window.localStorage.removeItem('userinfo');
      window.localStorage.removeItem('pid');
      window.localStorage.removeItem('login_shop');
      window.localStorage.removeItem('code');
      window.localStorage.removeItem('prev_url');
    }
  }
</script>

<style scoped>

</style>
